<template>
    <sub-visual
    :subTabNone="view"
    sh="법인소개"
    tabActive="위드 캔 소개"
    bg="/images/sub/visual/sv1.jpg">
    </sub-visual>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    props: {
        view: Boolean,
    },
    components: {
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
</style>