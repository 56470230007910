var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sub-visual', {
    attrs: {
      "subTabNone": _vm.view,
      "sh": "법인소개",
      "tabActive": "위드 캔 소개",
      "bg": "/images/sub/visual/sv1.jpg"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }