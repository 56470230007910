var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-intro'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('tab-intro'), _c('v-row', {
    staticClass: "mb-16 mb-lg-28",
    attrs: {
      "no-gutters": "",
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-8 mb-md-0 mr-md-12 mr-lg-20",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('h5', {
    staticClass: "tit tit--sm"
  }, [_vm._v(" 기본형 ")])]), _c('v-col', {
    staticClass: "mb-8 mb-md-0 mr-md-12 mr-lg-20",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v(" 기본형으로 심벌마크를 로고타입 앞에 배열해 시각적 안정감과 흐름을 중요시 하였습니다. ")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', {
    staticClass: "grey-b3 image-card",
    staticStyle: {
      "background-image": "url(/images/sub/company/intro/s1-bg.jpg)"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 pa-md-40 min-h-md-260px min-h-lg-300px d-flex align-center"
  }, [_c('v-row', {
    staticClass: "ma-n10 ma-lg-n20",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-10 pa-lg-20",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mw-80px mw-lg-140px mx-auto",
    attrs: {
      "src": "/images/ci-vertical2.svg",
      "contain": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-10 pa-lg-20",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mw-180px mw-lg-280px mx-auto",
    attrs: {
      "src": "/images/ci-vertical.svg",
      "contain": ""
    }
  })], 1)], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', {
    staticClass: "grey-b3 image-card",
    staticStyle: {
      "background-image": "url(/images/sub/company/intro/s1-bg.jpg)"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 pa-md-40 min-h-md-260px min-h-lg-300px d-flex align-center"
  }, [_c('v-img', {
    staticClass: "mw-220px mw-lg-320px mx-auto",
    attrs: {
      "src": "/images/ci.svg",
      "contain": ""
    }
  })], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }