<template>
    <v-tabs hide-slider height="auto" class="v-tabs--primary tab--block">
        <v-tab to="/company/intro">소개</v-tab>
        <v-tab to="/company/greeting">인사말</v-tab>
        <!-- <v-tab to="/company/ci">CI 소개</v-tab> -->
        <v-tab to="/company/history">연혁</v-tab>
        <v-tab to="/company/organization">조직도</v-tab>
        <!-- <v-tab to="/company/operation">투명한 운영</v-tab> -->
        <v-tab to="/company/supporters">셀럽 서포터즈</v-tab>
        <v-tab to="/company/location">오시는 길</v-tab>
    </v-tabs>
</template>

<script>
export default {
    components: {
    },
};
</script>

<style lang="scss" scoped>
</style>