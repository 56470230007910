var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    staticClass: "v-tabs--primary tab--block",
    attrs: {
      "hide-slider": "",
      "height": "auto"
    }
  }, [_c('v-tab', {
    attrs: {
      "to": "/company/intro"
    }
  }, [_vm._v("소개")]), _c('v-tab', {
    attrs: {
      "to": "/company/greeting"
    }
  }, [_vm._v("인사말")]), _c('v-tab', {
    attrs: {
      "to": "/company/history"
    }
  }, [_vm._v("연혁")]), _c('v-tab', {
    attrs: {
      "to": "/company/organization"
    }
  }, [_vm._v("조직도")]), _c('v-tab', {
    attrs: {
      "to": "/company/supporters"
    }
  }, [_vm._v("셀럽 서포터즈")]), _c('v-tab', {
    attrs: {
      "to": "/company/location"
    }
  }, [_vm._v("오시는 길")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }