<template>
    <client-page>

        <sub-visual-intro />

        <section class="section">
            <v-container>

                <tab-intro />

                <v-row no-gutters align-md="center" class="mb-16 mb-lg-28">
                    <v-col cols="12" md="auto" class="mb-8 mb-md-0 mr-md-12 mr-lg-20">
                        <h5 class="tit tit--sm">
                            기본형
                        </h5>
                    </v-col>
                    <v-col cols="12" md="auto" class="mb-8 mb-md-0 mr-md-12 mr-lg-20">
                        <p class="page-text page-text--lg">
                            기본형으로 심벌마크를 로고타입 앞에 배열해 시각적 안정감과 흐름을 중요시 하였습니다.
                        </p>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="7">
                        <v-card outlined class="grey-b3 image-card" style="background-image: url(/images/sub/company/intro/s1-bg.jpg);">
                            <div class="w-100 pa-30 pa-md-40 min-h-md-260px min-h-lg-300px d-flex align-center">
                                <v-row class="ma-n10 ma-lg-n20" justify="center" align="center">
                                    <v-col cols="auto" class="pa-10 pa-lg-20">
                                        <v-img src="/images/ci-vertical2.svg" contain class="mw-80px mw-lg-140px mx-auto"></v-img>
                                    </v-col>
                                    <v-col cols="auto" class="pa-10 pa-lg-20">
                                        <v-img src="/images/ci-vertical.svg" contain class="mw-180px mw-lg-280px mx-auto"></v-img>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-card outlined class="grey-b3 image-card" style="background-image: url(/images/sub/company/intro/s1-bg.jpg);">
                            <div class="w-100 pa-30 pa-md-40 min-h-md-260px min-h-lg-300px d-flex align-center">
                                <v-img src="/images/ci.svg" contain class="mw-220px mw-lg-320px mx-auto"></v-img>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualIntro from "@/components/client/sub/sub-visual-intro.vue";
import TabIntro from "@/components/client/sub/tab-intro.vue";
export default {
    components: {
        ClientPage,
        SubVisualIntro,
        TabIntro,
    },
};
</script>

<style lang="scss" scoped>
</style>